<template>
<section>
            <v-layout justify-center>
    <v-container>

    <v-row justify="center">
             <v-col
                cols="6" sm="4"
                v-for="card in cards"
                v-bind="{ [`xs${card.flex}`]: true }"
                :key="card.title"
                router :to="card.action"
              >
                <v-card>
                  <v-img
                    :src="card.src"
                    height="200px"
                    @click="routeDashboard(card)" 
                    style="cursor: pointer"
                  >
                    
                  </v-img>
  
                  <v-card-actions v-show="card.showDetails">
                    {{card.description}}
                  </v-card-actions>
                </v-card>
             </v-col>
        </v-row>
    </v-container>
         </v-layout>
</section>
</template>
<script>

export default {
    name: 'Components',
    components: {
    },
    data () {
        return {
            cards: [
      { title: 'Pre-fab homes', src: 'https://www.materialui.co/materialIcons/action/home_black_144x144.png', flex: 6, description: "Home", 
            showDetails: true, action: "/homes"},
      { title: 'Pre-fab homes', src: 'https://www.materialui.co/materialIcons/action/account_box_black_144x144.png', flex: 6, description: "Residents", 
            showDetails: true, action: "/residents"},
      { title: 'Favorite road trips', src: 'https://www.materialui.co/materialIcons/maps/local_pharmacy_black_144x144.png', flex: 6,  description: "Medications",
            showDetails: true, action: "/medications"},
      // { title: 'Best airlines', src: 'https://www.materialui.co/materialIcons/content/add_box_black_144x144.png', flex: 6,description: "Medication Administration",
      //       showDetails: true, action: "/medicationsAdministration"},
      // { title: 'Best airlines', src: 'https://www.materialui.co/materialIcons/av/add_to_queue_black_192x192.png', flex: 6,description: "Control Drug",
      //       showDetails: true, action: "/controlDrugRegister"},
      // { title: 'Best airlines', src: 'https://www.materialui.co/materialIcons/content/add_box_black_144x144.png', flex: 6,description: "Control Drug Administration",
      //       showDetails: true, action: "/controlDrugAdmin"},
      // { title: 'Best airlines', src: 'https://www.materialui.co/materialIcons/content/add_box_black_144x144.png', flex: 6,description: "Remedies",
      //       showDetails: true, action: "/remedies"},
      { title: 'Best airlines', src: 'https://www.materialui.co/materialIcons/action/home_black_144x144.png', flex: 6,description: "Homely Remedies",
            showDetails: true, action: "/homelyRemedies"}
    ]
        }
    },
    methods: {
        routeDashboard (card){
            this.$router.push(card.action)
        }
    },
}
</script>
<style lang="scss">
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
.paginate-links.items {
  user-select: none;
  a {
    cursor: pointer;
  }
  li.active a {
    font-weight: bold;
  }
  li.next:before {
    content: ' | ';
    margin-right: 13px;
    color: #ddd;
  }
  li.disabled a {
    color: #ccc;
    cursor: no-drop;
  }
}
a {
  color: #42b983;
}
</style>